import { LogsService } from '@/shared/api';
import { InfoPage } from '@/shared/ui/InfoPage/InfoPage';
import React, { Component, ReactNode, ErrorInfo } from 'react';
import error from '@pages/Auth/assets/Warning.svg';
import { withTranslation, WithTranslation } from 'react-i18next';
import styles from './styles.module.scss';

interface ErrorBoundaryProps extends WithTranslation {
    children: ReactNode;
}

interface ErrorBoundaryState {
    hasError: boolean;
    reloadAttempted: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false, reloadAttempted: false };
    }

    static getDerivedStateFromError(): ErrorBoundaryState {
        return { hasError: true, reloadAttempted: false };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
        LogsService.postLog({ requestBody: { Msg: `error: ${error}; errorInfo: ${JSON.stringify(errorInfo)}` } });

        // Если ошибка связана с загрузкой модулей, попробуем обновить страницу
        if (error.message.includes('Failed to fetch dynamically imported module')) {
            if (!this.state.reloadAttempted) {
                this.setState({ reloadAttempted: true });
                window.location.reload();
            }
        }
    }

    render() {
        if (this.state.hasError) {
            const { t } = this.props;
            return (
                <div className={styles.errorBoundary}>
                    <InfoPage title={t('error')} subtitle={t('errorBoundaryMsg')} img={error} />
                </div>
            );
        }

        return this.props.children;
    }
}

export default withTranslation('global')(ErrorBoundary);
