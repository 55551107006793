import { useMemo, useState } from 'react';
import { Player } from '@shared/api';

type PlyerFilters = {
    offset: number;
    order: 'asc' | 'desc';
    maxRating: number;
    maxDiff: number | undefined;
    minRating: number;
    minDiff: number | undefined;
    sort: ('name' | 'rating' | 'last_update')[];
    name: string[];
    loc: number[];
};

const MIN = -1000,
    MAX = 3000;

const urlParams = new URLSearchParams(window.location.search);

// Извлекаем параметры
const parsedFilters: Partial<PlyerFilters> = {};

if (urlParams.has('maxRating')) {
    parsedFilters.maxRating = Number(urlParams.get('maxRating')) || MAX;
}

if (urlParams.has('minRating')) {
    parsedFilters.minRating = Number(urlParams.get('minRating')) || MIN;
}

if (urlParams.has('name')) {
    parsedFilters.name = Array.from(urlParams.getAll('name'));
}

if (urlParams.has('loc')) {
    parsedFilters.loc = Array.from(urlParams.getAll('loc')).map(Number);
}

export const usePlayers = () => {
    const [players, setPlayers] = useState<Player[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [count, setCount] = useState(0);

    const [offset, setOffset] = useState(0);
    const [order, setOrder] = useState<'asc' | 'desc'>('desc');
    const [maxRating, setMaxRating] = useState<number>(parsedFilters.maxRating || MAX);
    const [maxDiff, setMaxDiff] = useState<number>();
    const [minRating, setMinRating] = useState<number>(parsedFilters.minRating || MIN);
    const [minDiff, setMinDiff] = useState<number>();
    const [sort, setSort] = useState<('name' | 'rating' | 'last_update')[]>(['rating']);
    const [location, setLocation] = useState<number[]>(parsedFilters.loc || []);
    const [name, setName] = useState<string[]>(parsedFilters.name || []);
    const [cities, setCities] = useState<
        {
            value: string;
            label: string;
        }[]
    >([]);
    const allFilters = useMemo(
        () => ({
            offset,
            order,
            maxRating,
            maxDiff,
            minRating,
            minDiff,
            sort,
            name,
            loc: location,
        }),
        [offset, order, maxRating, maxDiff, minRating, minDiff, sort, name, location]
    );
    return {
        players,
        isLoading,
        count,
        maxRating,
        minRating,
        cities,
        name,

        MIN,
        MAX,

        allFilters,

        setPlayers,
        setIsLoading,
        setCount,
        setOffset,
        setOrder,
        setMaxRating,
        setMaxDiff,
        setMinRating,
        setMinDiff,
        setSort,
        setLocation,
        setName,
        setCities,
    };
};
