import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';

import './global.scss';

import { App } from './App';
import { AuthProvider } from '@app/AuthProvider';
import { StoreProvider } from './store/store';
import { BrowserRouter } from 'react-router-dom';
import ScrollRestoration from './ScrollRestoration';
import ErrorBoundary from './errorBoundary/ErrorBoundary';

const APP_VERSION = '1.0.2';
const FORCE_CLEAR_KEY = `sw_cleared_${APP_VERSION}`;

async function clearSWCache() {
    try {
        if ('serviceWorker' in navigator) {
            const registrations = await navigator.serviceWorker.getRegistrations();
            for (const registration of registrations) {
                if (registration.waiting) {
                    await registration.waiting.postMessage({ type: 'SKIP_WAITING' });
                }
                await registration.unregister();
            }
        }
        if ('caches' in window) {
            const cacheNames = await caches.keys();
            await Promise.all(cacheNames.map((name) => caches.delete(name)));
        }
    } catch (error) {
        // eslint-disable-next-line
        console.error('Cache cleanup failed:', error);
    }
}

async function checkVersion() {
    if (!localStorage.getItem(FORCE_CLEAR_KEY)) {
        await clearSWCache();
        localStorage.setItem(FORCE_CLEAR_KEY, 'true');
        window.location.replace(window.location.pathname + `?v=${APP_VERSION}&t=${Date.now()}`);
    }
}

// Запуск проверки и рендеринг
checkVersion().then(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    createRoot(document.getElementById('root')!).render(
        <React.StrictMode>
            <Suspense fallback={<div>Loading...</div>}>
                <BrowserRouter>
                    <ErrorBoundary>
                        <ScrollRestoration />
                        <AuthProvider>
                            <StoreProvider>
                                <App />
                            </StoreProvider>
                        </AuthProvider>
                    </ErrorBoundary>
                </BrowserRouter>
            </Suspense>
        </React.StrictMode>
    );
});
