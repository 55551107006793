import { useCallback, useState } from 'react';

import { FiltersGames, ProfileData, ProfileTabs } from '@entities/profile/model/types';
import { PlayerService, PlayerTournament } from '@shared/api';
import { useParams } from 'react-router-dom';

export type TournamentsFilters = {
    name: string;
    city: string[];
    year: string;
};

export const TabList: ProfileTabs[] = ['statistics', 'rating', 'tournaments', 'opponents', 'games'];

export const useProfile = () => {
    const [profile, setProfile] = useState<ProfileData>();
    const [federation, setFed] = useState<string>('rgf');
    const [activeTab, setActiveTab] = useState<ProfileTabs>();
    const [tabList, setTabList] = useState<ProfileTabs[]>(TabList);
    const [tournaments, setTournaments] = useState<PlayerTournament[]>([]);
    const [tournamentsFilter, setTournamentsFilter] = useState<TournamentsFilters>({ name: '', city: [], year: '' });
    const [filtersGames, setFiltersGames] = useState<Partial<FiltersGames>>({});
    const [currentLang, setCurrentLang] = useState('');
    const [defaultTab, setDefaultTab] = useState<ProfileTabs>();

    const [scrollPosition, setScrollPosition] = useState(0);

    const { id } = useParams();

    const setFederation = useCallback(
        (fed: string) => {
            if (typeof id === 'undefined') return;
            PlayerService.getPlayersRating({ id: id, federation: fed }).then((history) => {
                setFed(fed);
                if (profile) {
                    setProfile({ ...profile, ratingHistory: history });
                }
            });
        },
        [id, profile]
    );

    return {
        data: profile,
        activeTab,
        setProfile,
        setActiveTab,
        federation,
        setFederation,
        tournaments,
        setTournaments,
        tournamentsFilter,
        setTournamentsFilter,
        tabList,
        setTabList,
        scrollPosition,
        setScrollPosition,
        filtersGames,
        setFiltersGames,
        currentLang,
        setCurrentLang,
        defaultTab,
        setDefaultTab,
    };
};
